import React from "react";
import { Link } from "@reach/router";
import logo from "../resources/playMakerLogo.jpg";
import { Layout } from "antd";
import useIsMobile from "../utils/useIsMobile";

const NavHeader = ({ var1, var2 }) => {
  const isMobile = useIsMobile();
  return (
    <Layout
      style={{
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
        alignContent: "center",
        width: 200,
        background: "#406AB6",
        padding: isMobile ? 0 : 20,
      }}>
      <Link to="/" className={"logo center"}>
        <img src={logo} alt="logo" />
      </Link>
    </Layout>
  );
};

export default NavHeader;
