import { Card, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getRosterStats,
  selectRosterStatsByPlayerIdAndSeasonId,
  selectRosterStatsDataLoading,
} from "../../redux/rosterStats_slice";
import { useEffect } from "react";
import "../../css/roster_ranks.css";

const dataToTableMapping = [
  {
    key: "row1",
    name: "Strength of Schedule",
    data1: "strengthOfSchedule_DivRank",
    data2: "strengthOfSchedule_StateRank",
  },
  {
    key: "row2",
    name: "Total Offense Per Game",
    data1: "Offense_TOT_YPG_DivRank",
    data2: "Offense_TOT_YPG_StateRank",
  },
  {
    key: "row3",
    name: "Total Passing Per Game",
    data1: "Offense_PASS_YDS_PG_DivRank",
    data2: "Offense_PASS_YDS_PG_StateRank",
  },
  {
    key: "row4",
    name: "Total Rushing Per Game",
    data1: "Offense_RUSH_YDS_PG_DivRank",
    data2: "Offense_RUSH_YDS_PG_StateRank",
  },
  {
    key: "row5",
    name: "Passing TDs Per Game",
    data1: "Offense_PASS_TD_PG_DivRank",
    data2: "Offense_PASS_TD_PG_StateRank",
  },
  {
    key: "row6",
    name: "Rushing TDs Per Game",
    data1: "Offense_RUSH_TD_PG_DivRank",
    data2: "Offense_RUSH_TD_PG_StateRank",
  },
  {
    key: "row7",
    name: "Total TDs Per Game",
    data1: "Offense_TOT_TD_PG_DivRank",
    data2: "Offense_TOT_TD_PG_StateRank",
  },
  {
    key: "row8",
    name: "Points Scored Per Game",
    data1: "POINTS_FOR_PG_DivRank",
    data2: "POINTS_FOR_PG_StateRank",
  },
  {
    key: "row9",
    name: "Total Sacks Per Game",
    data1: "Defense_SACK_PG_DivRank",
    data2: "Defense_SACK_PG_StateRank",
  },
  {
    key: "row10",
    name: "Total Interceptions Per Game",
    data1: "Defense_INT_PG_DivRank",
    data2: "Defense_INT_PG_StateRank",
  },
  {
    key: "row11",
    name: "Turnovers Per Game",
    data1: "Defense_TO_PG_DivRank",
    data2: "Defense_TO_PG_StateRank",
  },
  {
    key: "row12",
    name: "Points Allowed Per Game",
    data1: "POINTS_AGAINST_PG_DivRank",
    data2: "POINTS_AGAINST_PG_StateRank",
  },
];

const fillInRowData = (row, data) => {
  return {
    ...row,
    data1: data[row.data1],
    data2: data[row.data2],
    divisionCount: data?.divisionCount,
    stateCount: data?.stateCount,
  };
};

const RosterRanks = ({ playerId, seasonId }) => {
  const dispatch = useDispatch();

  const rosterStatsLoading = useSelector(selectRosterStatsDataLoading);

  const rosterStatsData = useSelector(
    selectRosterStatsByPlayerIdAndSeasonId(playerId, seasonId),
  );

  useEffect(() => {
    if (playerId && seasonId && !rosterStatsLoading && !rosterStatsData) {
      dispatch(getRosterStats(playerId, seasonId));
    }
  }, [playerId, seasonId]);

  const displayData =
    rosterStatsData && rosterStatsData?.roster_ranks[0]
      ? dataToTableMapping.map(r =>
          fillInRowData(r, rosterStatsData?.roster_ranks[0]),
        )
      : null;

  const columns = [
    {
      title: "",
      dataIndex: "name",
      // width: 300,
      fixed: true,
    },
    {
      title: rosterStatsData?.roster_ranks[0]?.stateDivisionName
        ? `${rosterStatsData?.roster_ranks[0]?.stateDivisionName} Division Rank`
        : "Not ranked",
      dataIndex: "data1",
      render: (value, record) => {
        return record.divisionCount
          ? `${value}/${record?.divisionCount}`
          : `${value} (Not ranked)`;
      },
      // width: 300,
      fixed: true,
    },
    {
      title: rosterStatsData?.roster_ranks[0]?.state
        ? `${rosterStatsData?.roster_ranks[0]?.state} State Rank`
        : "Not ranked",
      dataIndex: "data2",
      render: (value, record) => {
        return record.stateCount
          ? `${value}/${record?.stateCount}`
          : `${value} (Not ranked)`;
      },
      // width: 300,
      fixed: true,
    },
  ];

  const shouldAddDividerClassName = (record, index) => {
    if (
      ["Strength of Schedule", "Points Scored Per Game"].includes(record?.name)
    ) {
      return "withRowDivider";
    }
  };
  return (
    <Card style={{ marginBottom: "10px", height: "100%" }}>
      <h1>Roster Rankings</h1>
      <Table
        rowClassName={shouldAddDividerClassName}
        columns={columns}
        dataSource={displayData}
        pagination={false}
        tableLayout="fixed"
        loading={rosterStatsLoading}
        size="small"
      />
    </Card>
  );
};

export default RosterRanks;
