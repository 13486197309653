import { Card, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getRosterStats,
  selectRosterStatsByPlayerIdAndSeasonId,
  selectRosterStatsDataLoading,
} from "../../redux/rosterStats_slice";
import { useEffect } from "react";

const RosterRecords = ({ playerId, seasonId }) => {
  const dispatch = useDispatch();

  const rosterStatsLoading = useSelector(selectRosterStatsDataLoading);

  const rosterStatsData = useSelector(
    selectRosterStatsByPlayerIdAndSeasonId(playerId, seasonId),
  );

  useEffect(() => {
    if (playerId && seasonId && !rosterStatsLoading && !rosterStatsData) {
      dispatch(getRosterStats(playerId, seasonId));
    }
  }, [playerId, seasonId]);

  const data = [
    {
      fieldName: "Team W/L",
      fieldValue:
        rosterStatsData && rosterStatsData?.roster_records[0]
          ? `${rosterStatsData?.roster_records[0]?.wins ?? "N/A"} - ${rosterStatsData?.roster_records[0]?.losses ?? "N/A"}`
          : "N/A",
    },
    {
      fieldName: "Opponent W/L",
      fieldValue:
        rosterStatsData && rosterStatsData?.roster_records[0]
          ? `${rosterStatsData?.roster_records[0]?.oppWins ?? "N/A"} - ${rosterStatsData?.roster_records[0]?.oppLosses ?? "N/A"}`
          : "N/A",
    },
    {
      fieldName: "Opp-Opp W/L",
      fieldValue:
        rosterStatsData && rosterStatsData?.roster_records[0]
          ? `${rosterStatsData?.roster_records[0]?.oppOppWins ?? "N/A"} - ${rosterStatsData?.roster_records[0]?.oppOppLosses ?? "N/A"}`
          : "N/A",
    },
  ];

  // const data = transformData(rosterStatsData);

  const columns = [
    {
      title: "Strength Of Schedule",
      dataIndex: "fieldName",
      // width: 300,
      fixed: true,
    },
    {
      title: rosterStatsData?.roster_records[0]?.strengthOfSchedule ?? "N/A",
      dataIndex: "fieldValue",
      // width: 300,
      fixed: true,
    },
  ];
  return (
    <Card style={{ marginBottom: "10px", height: "100%" }}>
      <h1>Roster Records</h1>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        tableLayout="fixed"
        loading={rosterStatsLoading}
        size="small"
      />
    </Card>
  );
};

export default RosterRecords;
