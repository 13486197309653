import { Grid } from "antd";

const { useBreakpoint } = Grid;

const useIsMobile = () => {
  const breakpoints = useBreakpoint();
  console.log("breakpoints", breakpoints);

  return breakpoints.xs;
};

export default useIsMobile;
