import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";

import { Button, Layout, Menu, Space } from "antd";
import {
  HomeOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";

import "../css/nav.css";
import NavHeader from "./navHeader";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../redux/user_slice";
import Signout from "./signOut";
import useIsMobile from "../utils/useIsMobile";
import { Header } from "antd/es/layout/layout";
import MobileLogo from "./mobileLogo";

const { Content, Sider } = Layout;

const SubMenu = ({ showForGroup, ...props }) => {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.user);

  useEffect(() => {
    if (!userData.userId) {
      dispatch(getUser());
    }
  }, [dispatch, userData.userId]);

  if (
    showForGroup === "all" ||
    (userData &&
      userData.userGroups &&
      userData.userGroups.includes(showForGroup)) ||
    (showForGroup === "other" &&
      userData &&
      (!userData.userGroups || userData.userGroups.length === 0)) ||
    (userData.userGroups && userData.userGroups.includes("admins"))
  ) {
    return <Menu.ItemGroup {...props}></Menu.ItemGroup>;
  } else return null;
};

// const MobileMenu = () => {

// }

const Navs = props => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const navToKey = ({ item, key, domEvent }) => {
    navigate(key);
    setSelectedKeys([key]);
  };

  const isMobile = useIsMobile();
  console.log("isMobile", isMobile);

  return (
    <Layout
      hasSider={false}
      style={{
        marginLeft: isMobile ? 0 : 200,
        marginRight: isMobile ? 5 : 10,
        height: "100vh",
        overflow: "scroll",
      }}>
      <Sider
        trigger={null}
        breakpoint="sm"
        collapsedWidth="0"
        collapsed={collapsed}
        onCollapse={value => {
          setCollapsed(value);
        }}
        collapsible
        style={{
          zIndex: 10000,
          overflow: "auto",
          // height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          background: "#406AB6",
          // width: "100%",
        }}>
        <Space direction={"vertical"}>
          <NavHeader />
          <Menu mode="inline" onClick={navToKey} selectedKeys={selectedKeys}>
            <SubMenu
              key="/recruiters"
              title={"Recruiting"}
              showForGroup="recruiters">
              <Menu.Item key="/">
                <span>
                  <HomeOutlined />
                  Home
                </span>
              </Menu.Item>
              <Menu.Item key="/recruiters/statleaders">Stat Leaders</Menu.Item>
              <Menu.Item key="/recruiters/playerpipeline">
                Player Pipeline
              </Menu.Item>
              <Menu.Item key="/recruiters/research">Research</Menu.Item>
              <Menu.Item key="/recruiters/lists">Saved Lists</Menu.Item>
              <Menu.Item key="/recruiters/needs">Team Needs</Menu.Item>
              <Menu.Item key="/recruiters/referrals/sharedprofiles">
                Viewed Profiles
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="/coaches"
              title={"Coach's Info"}
              showForGroup="coaches">
              <Menu.Item key="/">
                <span>
                  <HomeOutlined />
                  Home
                </span>
              </Menu.Item>
              <Menu.Item key="/coaches/dashboard">Team Dashboard</Menu.Item>
              <Menu.Item key="/coaches/schedule">Team Schedule</Menu.Item>
              <Menu.Item key="/coaches/analytics">Team Analytics</Menu.Item>
              <Menu.Item key="/coaches/scouting">Scouting</Menu.Item>
              <Menu.Item key="/coaches/roster">Team Rosters</Menu.Item>
              <Menu.Item key="/coaches/depth">Depth Charts</Menu.Item>
              <Menu.Item key="/coaches/non-game-data">Non-Game Data</Menu.Item>
              <Menu.Item key="/coaches/prospect-list">Prospect List</Menu.Item>
              <Menu.Item key="/coaches/school-profile">
                School Profile
              </Menu.Item>
              <Menu.Item key="/coaches/referrals/sharedprofiles">
                Viewed Profiles
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="/players"
              title={"Player Info"}
              showForGroup="players">
              <Menu.Item key="/">
                <span>
                  <HomeOutlined />
                  Home
                </span>
              </Menu.Item>
              <Menu.Item key="/players/dashboard">Dashboard</Menu.Item>
              <Menu.Item key="/players/profile">Profile</Menu.Item>
              <Menu.Item key="/players/training">Training</Menu.Item>
              <Menu.Item key="/players/recruiting-center">
                Recruiting Center
              </Menu.Item>
              <Menu.Item key="/players/team">Team Information</Menu.Item>
            </SubMenu>

            <SubMenu key="/admin" title={"Admin"} showForGroup="admins">
              <Menu.Item key="/">
                <span>
                  <HomeOutlined />
                  Home
                </span>
              </Menu.Item>
              <Menu.Item key="/admin/stagedusers">Staged Users</Menu.Item>
            </SubMenu>

            <SubMenu key="/referrals" title={"Referrals"} showForGroup="other">
              <Menu.Item key="/">
                <span>
                  <HomeOutlined />
                  Home
                </span>
              </Menu.Item>
              <Menu.Item key="/referrals/sharedprofiles">
                Shared Profiles
              </Menu.Item>
            </SubMenu>

            {/*<SubMenu key ="/comm" title={<span><MailOutlined/> Message Center</span>} showForGroup="all">*/}
            {/*    <Menu.Item key="/comm/">Comm Center</Menu.Item>*/}
            {/*    <Menu.Item key="/comm/inbox">Inbox</Menu.Item>*/}
            {/*    <Menu.Item key="/comm/outbox">Sent Messages</Menu.Item>*/}
            {/*    <Menu.Item key="/comm/all">All Messages</Menu.Item>*/}
            {/*</SubMenu>*/}

            {/*<Menu.Item key="/user">*/}
            {/*    <span><UserOutlined/>User Settings</span>*/}
            {/*</Menu.Item>*/}
          </Menu>

          <Layout
            style={{
              margin: 20,
              bottom: 0,
            }}>
            <Signout />
          </Layout>
        </Space>
      </Sider>
      {/* <Layout
        //className="site-layout"
        style={{
          marginLeft: isMobile ? 0 : 200,
          marginRight: isMobile ? 5 : 10,
          // height: "100vh",
        }}> */}
      {isMobile && (
        <Header
          breakpoint="sm"
          style={{
            left: 0,
            top: 0,
            padding: 20,
            background: "#406AB6",
            position: "sticky",
            zIndex: 10001,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <MobileLogo />
          <Button
            type="text"
            icon={<MenuOutlined style={{ color: "white" }} />}
            onClick={() => setCollapsed(prev => !prev)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
        </Header>
      )}
      <Content
        onClick={() => {
          if (isMobile && !collapsed) {
            setCollapsed(true);
          }
        }}>
        {props.children}
      </Content>
      {/* </Layout> */}
    </Layout>
  );
};

export default Navs;
