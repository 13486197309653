import React from "react";
import { Link } from "@reach/router";
import logo from "../resources/playMakerLogo.jpg";
import { Layout } from "antd";

const MobileLogo = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <Link to="/" className={"logo center"}>
        <img src={logo} alt="logo" />
      </Link>
    </div>
  );
};

export default MobileLogo;
